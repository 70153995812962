:root {
    // Primary
    --primary-light: #E0E9F8;
    --primary-bright: #A2A8F4;
    --primary-ymunity: #797EF5;
    --primary-hover: #4841BF;
    --primary-neutro: #7679A3;
    --primary-sidebar: #272D63;
    --primary-dark: #141B4D;
    --primary-400: #373D68;

    // Secondary/info
    --system-info-background: #E9F3FF;
    --system-info-light: #A7C6ED;
    --system-info-info: #0086D6;
    --system-info-bright: #3429F0;
    --system-info-dark: #1F299B;

    // Warning
    --system-warning-background: #FFF8F3;
    --system-warning-300: #FBA86F;
    --system-warning-500: #F97316;
    --system-warning-700: #AE510F;

    // Success
    --system-success-background: #E4F8F0;
    --system-success-300: #76DB9B;
    --system-success-600: #188A42;
    --system-success-900: #0E4F26;

    // Danger
    --system-danger-background: #FFF5F5;
    --system-danger-200: #FFD0CE;
    --system-danger-300: #FF8780;
    --system-danger-600: #D9342B;
    --system-danger-700: #B32B23;

    // Status
    --status-active: #22C55E;
    --status-active_bg: #CAF1D8;
    --status-inactive: #D9342B;
    --status-inactive_bg: #FFD0CE;
    --status-info: #0086D6;
    --status-warning: #F97316;
    --status-warning_bg: #FFF8F3;
    --status-info_bg: #E9F3FF;
    --status-neutral: #7679A3;
    --status-neutral_bg: #DADEE3;

    // Grayscale
    --grayscale-surface-black: #000000;
    --grayscale-surface-white: #FFFFFF;
    --grayscale-surface-hover: #F6F9FC;
    --grayscale-surface-ground: #EFF3F8;
    --grayscale-50: #F7F8F9;
    --grayscale-100: #DADEE3;
    --grayscale-200: #BCC3CD;
    --grayscale-300: #9FA9B7;
    --grayscale-400: #818EA1;
    --grayscale-500: #64748B;
    --grayscale-600: #556376;
    --grayscale-700: #465161;
    --grayscale-800: #37404C;
    --grayscale-900: #282E38;

    // grey
    --grey-400: #BDBDBD;

    // Surface
    --surface-900: #212121;

    // Gradients
    --gradient-ymunity-indigo: linear-gradient(22deg, #281FA8 5.32%, var(--primary-bright) 100.16%);
    --gradient-ymunity-blue: linear-gradient(22deg, #281FA8 5.32%, #00E6F7 100.16%);
    --gradient-ymunity-light: linear-gradient(22deg, var(--system-info-info) 5.32%, var(--primary-light) 100.16%);
    --gradient-ymunity-login-background: linear-gradient(var(--primary-dark), #25368B);

    // Unspecified
    --text-secondary: #5B5F82;
    --ymunity-border-shadow: #A2A8F4D1;
    --ymunity-highlight: #A2A8F4;
    --ymunity-password-fields: #6C757D;

    //Sizes

    --font-size-table-default: 14px;
}